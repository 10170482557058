import React, {FC, useEffect} from "react";
import {useAction} from "../../../../../store/hooks/useAction";
import {useTypedSelector} from "../../../../../store/hooks/useTypedSelector";
import {useIntl} from "react-intl";
import {GraphChart} from "../../componenets/GraphChart";
interface TI {
    currency: string,
    operator: string
}
let fakeData = {
    "total_sales": 50600,
    "total_ggr": 66700,
    "sales_data": [
        {
            "date": "2024-04-01",
            "value": 0
        },
        {
            "date": "2024-04-02",
            "value": 0
        },
        {
            "date": "2024-04-03",
            "value": 0
        },
        {
            "date": "2024-04-04",
            "value": 0
        },
        {
            "date": "2024-04-05",
            "value": 42000
        },
        {
            "date": "2024-04-06",
            "value": 0
        },
        {
            "date": "2024-04-07",
            "value": 0
        },
        {
            "date": "2024-04-08",
            "value": 2600
        },
        {
            "date": "2024-04-09",
            "value": 0
        },
        {
            "date": "2024-04-10",
            "value": 0
        },
        {
            "date": "2024-04-11",
            "value": 0
        },
        {
            "date": "2024-04-12",
            "value": 0
        },
        {
            "date": "2024-04-13",
            "value": 0
        },
        {
            "date": "2024-04-14",
            "value": 6000
        },
        {
            "date": "2024-04-15",
            "value": 0
        },
        {
            "date": "2024-04-16",
            "value": 0
        },
        {
            "date": "2024-04-17",
            "value": 0
        },
        {
            "date": "2024-04-18",
            "value": 0
        },
        {
            "date": "2024-04-19",
            "value": 0
        },
        {
            "date": "2024-04-20",
            "value": 0
        },
        {
            "date": "2024-04-21",
            "value": 0
        },
        {
            "date": "2024-04-22",
            "value": 0
        },
        {
            "date": "2024-04-23",
            "value": 0
        }
    ],
    "ggr_data": [
        {
            "date": "2024-04-01",
            "value": 0
        },
        {
            "date": "2024-04-02",
            "value": 0
        },
        {
            "date": "2024-04-03",
            "value": 0
        },
        {
            "date": "2024-04-04",
            "value": 0
        },
        {
            "date": "2024-04-05",
            "value": 0
        },
        {
            "date": "2024-04-06",
            "value": 0
        },
        {
            "date": "2024-04-07",
            "value": 0
        },
        {
            "date": "2024-04-08",
            "value": 0
        },
        {
            "date": "2024-04-09",
            "value": 0
        },
        {
            "date": "2024-04-10",
            "value": 0
        },
        {
            "date": "2024-04-11",
            "value": 0
        },
        {
            "date": "2024-04-12",
            "value": 0
        },
        {
            "date": "2024-04-13",
            "value": 0
        },
        {
            "date": "2024-04-14",
            "value": 0
        },
        {
            "date": "2024-04-15",
            "value": 0
        },
        {
            "date": "2024-04-16",
            "value": 0
        },
        {
            "date": "2024-04-17",
            "value": 0
        },
        {
            "date": "2024-04-18",
            "value": 2845
        },
        {
            "date": "2024-04-19",
            "value": 4795
        },
        {
            "date": "2024-04-20",
            "value": 51115
        },
        {
            "date": "2024-04-21",
            "value": 0
        },
        {
            "date": "2024-04-22",
            "value": 0
        },
        {
            "date": "2024-04-23",
            "value": 7945
        }
    ]
}
const DailyGGRCurrentMonthGraphContainer: FC<TI> = ({currency, operator}) => {
    const {dailyGGRCurrentMonthGraph} = useAction();
    const {dailyGGRCurrentMonthGraphData} = useTypedSelector((state) => state.dailyGGRCurrentMonthGraphReducer);

    useEffect(()=> {
        if (operator) {
            dailyGGRCurrentMonthGraph(operator);
        }
    }, [operator])
    return(
        <>
            {
                Object.keys(dailyGGRCurrentMonthGraphData).length > 0 ?
                    <GraphChart data={dailyGGRCurrentMonthGraphData} currencyVal={currency} title={'DASHBOARD.dailyGGRCurrentMonth'}/>
                    :null
            }
        </>
    )
}

export default DailyGGRCurrentMonthGraphContainer;