import React, {FC} from "react";
import {useIntl} from 'react-intl';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
} from '@tanstack/react-table';
import {Link} from "react-router-dom";
import {
    ticketsStatusBadgeHelper,
    updateDate,
    ticketTypeBadgeHelper,
    ticketEventStatusBadgeHelper
} from "../helpers/ticket-helpers";
import {Alert} from "react-bootstrap";


interface BetengineTicketData {
    id: string;
    player_id: string;
    stake: number;
    net_payout: number;
    odds: number;
    events_progress: string;
    bets_progress: string;
    number_of_events: number;
    number_of_bets: number;
    result: string;
    status: string;
    type: string;
    operator_time_created: string;
    code: string;
}
interface BetengineTicketsResponse {
    data: BetengineTicketData[];
    limit: number;
    page: number;
    total: number
}
interface TicketsProps {
    betEngineTicketsData: BetengineTicketsResponse,
    setPaginationPage: (paginationNum: number) => void;
    handleRefreshData: () => void;
    paginationPage: number
}

const columnHelper = createColumnHelper<BetengineTicketData>();

const Tickets: FC  <TicketsProps> = ({betEngineTicketsData,setPaginationPage, paginationPage, handleRefreshData}) => {
    const intl = useIntl();
    const totalPages = Math.ceil(betEngineTicketsData.total / 10);
    const isBetEngineTicketsData = Object.keys(betEngineTicketsData).length > 0;

    const columns = [
        columnHelper.accessor('id', {
            cell: props => (
                <Link to={`${props.getValue()}`}>{props.row.original.code}</Link>
            ),
            id: 'Code',
            header: () => <span>{intl.formatMessage({id: 'BETENGINE.TICKETS.TABLE.HEADER.CODE'})}</span>,
        }),
        columnHelper.accessor('operator_time_created', {
            id: 'operator_time_created',
            cell: info => <div className='text-center'>
                <div>{updateDate(info.getValue()).formattedDate}</div>
            </div>,
            header: () => <span>{intl.formatMessage({id: 'BETENGINE.TICKETS.TABLE.HEADER.CREATED'})}</span>,
            footer: info => info.column.id,
        }),
        columnHelper.accessor('type', {
            id: 'type',
            cell: info => <div className='text-center'>
                <div>{ticketTypeBadgeHelper(info.getValue())}</div>
            </div>,
            header: () => <span>{intl.formatMessage({id: 'BETENGINE.TICKETS.TABLE.HEADER.TYPE'})}</span>,
            footer: info => info.column.id,
        }),
        columnHelper.accessor('player_id', {
            id: 'player_id',
            cell: info => <div className='text-center'>
                <div>{info.getValue()}</div>
            </div>,
            header: () => <span>{intl.formatMessage({id: 'BETENGINE.TICKETS.TABLE.HEADER.USERID'})}</span>,
            footer: info => info.column.id,
        }),
        columnHelper.accessor('stake', {
            id: 'stake',
            cell: info => <div className='text-center'>
                <div>{rounded(info.getValue())}</div>
            </div>,
            header: () => <span>{intl.formatMessage({id: 'BETENGINE.TICKETS.TABLE.HEADER.STAKE'})}</span>,
            footer: info => info.column.id,
        }),
        columnHelper.accessor('net_payout', {
            id: 'payout',
            cell: info => <div className='text-center'>
                <div title={'net_payout'}>{typeof info.getValue() === "number" ? rounded(info.getValue()) : null}</div>
            </div>,
            header: () => <span>{intl.formatMessage({id: 'BETENGINE.TICKETS.TABLE.HEADER.WIN'})}</span>,
            footer: info => info.column.id,
        }),
        columnHelper.accessor('odds', {
            id: 'odds',
            cell: info => <div className='text-center'>
                <div>{info.getValue()}</div>
            </div>,
            header: () => <span>{intl.formatMessage({id: 'BETENGINE.TICKETS.TABLE.HEADER.ODDS'})}</span>,
            footer: info => info.column.id,
        }),
        columnHelper.accessor('events_progress', {
            id: 'events_progress',
            cell: props => <div className='text-center'>
                <div>
                    {props.getValue() || props.row.original.bets_progress || ''}
                </div>
            </div>,
            header: () => <span>{intl.formatMessage({id: 'BETENGINE.TICKETS.TABLE.HEADER.PROGRESS'})}</span>,
            footer: info => info.column.id,
        }),
        columnHelper.accessor('number_of_events', {
            id: 'number_of_events',
            cell: props => <div className='text-center'>
                <div>{props.getValue() || props.row.original.number_of_bets || ''}</div>
            </div>,
            header: () => <span>{intl.formatMessage({id: 'BETENGINE.TICKETS.TABLE.HEADER.EVENTS'})}</span>,
            footer: info => info.column.id,
        }),
        columnHelper.accessor('status', {
            id: 'status',
            cell: info => <div className='text-center'>
                <div>{ticketEventStatusBadgeHelper(info.getValue(),info.row.original.result, {
                    'open': intl.formatMessage({id: 'STATUSCODE.OPEN'}),
                    'new': intl.formatMessage({id: 'STATUSCODE.NEW'}),
                    'closed': intl.formatMessage({id: 'STATUSCODE.CLOSED'}),
                    'lost': intl.formatMessage({id: 'STATUSCODE.LOST'}),
                    'canceled': intl.formatMessage({id: 'STATUSCODE.CANCELED'}),
                    'resulted': intl.formatMessage({id: 'STATUSCODE.RESULTED'})
                })}</div>
            </div>,
            header: () => <span>{intl.formatMessage({id: 'BETENGINE.TICKETS.TABLE.HEADER.STATUS'})}</span>,
            footer: info => info.column.id,
        }),

    ];
    const table = useReactTable({
        data:betEngineTicketsData.data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel()
    });

    const rounded = (num: number) => {
        let updatedVal = (num / 100).toFixed(2)
        return updatedVal;
    }

    return(
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10 betengine'>
            <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-12 mb-xl-12'>
                <div className="card mb-5 mb-xl-8">
                    <div className="card-header border-0 pt-5">
                        <div className='card-header-tools'>
                            <i className='las la-sync' onClick={() => {handleRefreshData()}} />
                            <h3 className='card-title align-items-start flex-column'>
                                <span className="card-label fw-bold fs-3 mb-1">{intl.formatMessage({id: 'BETENGINE.TICKETS.TITILE'})}</span>
                                <span className="text-muted mt-1 fw-semibold fs-7">{intl.formatMessage({id: 'BETENGINE.TICKETS.TITILE.HINT'})}</span>
                            </h3>
                        </div>
                        <div className="card-toolbar" />
                    </div>
                    <div className="card-body">
                        {
                            isBetEngineTicketsData ?
                                <div className="table-responsive quicktip-table">
                                    <table className='table table-row-bordered table-striped table-row-gray-100 align-middle gs-0 gy-3'>
                                        <thead>
                                        {table.getHeaderGroups().map(headerGroup => (
                                            <tr className='fw-bold text-muted' key={headerGroup.id}>
                                                {headerGroup.headers.map(header => {
                                                    return (
                                                        <th key={header.id} colSpan={header.colSpan} className='text-center'>
                                                            {header.isPlaceholder ? null : (
                                                                <div>
                                                                    {flexRender(
                                                                        header.column.columnDef.header,
                                                                        header.getContext()
                                                                    )}
                                                                </div>
                                                            )}
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        ))}
                                        </thead>
                                        <tbody>
                                        {table.getRowModel().rows.map(row => {
                                            return (
                                                <tr key={row.id}>
                                                    {row.getVisibleCells().map(cell => {
                                                        return (
                                                            <td className='text-center' key={cell.id}>
                                                                {flexRender(
                                                                    cell.column.columnDef.cell,
                                                                    cell.getContext()
                                                                )}
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                                : <Alert>{intl.formatMessage({id: 'STATUSCODE.LOADING'})}</Alert>
                        }

                        {/*Pagination Start*/}
                       <ul className="pagination">
                        <li className="page-item previous">
                            <button
                                className="page-link"
                                onClick={() => setPaginationPage(1)}
                            >
                                <i className="previous" />
                            </button>
                        </li>
                        <li className="page-item previous">
                            <button
                                className="btn btn-primary"
                                onClick={() => setPaginationPage(paginationPage - 1)}
                                disabled={paginationPage === 1}
                            >
                                {intl.formatMessage({id: 'TABLE.PAGINATION.PREVIOUS'})}
                            </button>
                        </li>
                        <li className="page-item previous">
                            <button
                                className="btn btn-primary"
                                onClick={() => setPaginationPage(paginationPage + 1)}
                                disabled={paginationPage === totalPages}
                            >
                                {intl.formatMessage({id: 'TABLE.PAGINATION.NEXT'})}
                            </button>
                        </li>
                        <li className="page-item next">
                            <button
                                className="page-link"
                                onClick={() => setPaginationPage(totalPages)}
                            >
                                <i className="next" />
                            </button>
                        </li>
                    </ul>
                        {/* ***Pagination End*** */}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Tickets;